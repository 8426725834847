import Layout from '@/components/layout/Layout';
import { FC } from 'react';
import BookCardsList from '@/components/ui/book-cards-list/BookCardsList';
import SectionSubtitle from '@/components/ui/section-sub-title/SectionSubTitle';
import BtnWithArrow from '@/components/ui/btn-with-arrow/BtnWithArrow';
import { useGetUrlPath } from '@/hooks/useGetUrlPath';
import { IBookPage } from '@/interfaces/booksPage.interface';
import styles from './Error.module.scss';

interface IError {
  newBooks: IBookPage;
}

const Error: FC<IError> = ({ newBooks }) => {
  //Seo block
  const SEO_TITLE = 'Ошибка 404';
  const SEO_DESCRIPTION = 'Запрошенной страницы нет на нашем сайте.Возможно, вы ввели неправильный адрес в строке браузера.';
  const SEO_CANONICAL = useGetUrlPath();

  return (
    <Layout title={SEO_TITLE} description={SEO_DESCRIPTION} canonicalHref={SEO_CANONICAL} pageHref={SEO_CANONICAL}>
      <div className={styles.errorBlock}>
        <div className={styles.errorBlock__img}></div>
        <div className={styles.errorBlock__desk}>
          <h1 className={styles.errorBlock__title}>Извините, такой страницы нет…</h1>
          <div className={styles.errorBlock__txt}>Попробуйте вернуться назад и повторить еще раз, или выберите из предложенного ниже.</div>
          <BtnWithArrow name="Перейти на главную" alias="/" />
        </div>
      </div>
      <SectionSubtitle name="Новые книги" />
      {!!newBooks.data.length ? <BookCardsList books={newBooks.data} /> : 'Книги не найдены'}
    </Layout>
  );
};

export default Error;
