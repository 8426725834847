import { FC } from 'react';
import Link from 'next/link';
import styles from './BtnWithArrow.module.scss';

interface IBtnWithArrow {
  alias: string;
  name: string;
}

const BtnWithArrow: FC<IBtnWithArrow> = ({ alias, name }) => {
  return (
    <Link prefetch={false} className={`${styles.btnWithArrow} ${styles.btn_orange_mobWide}`} href={alias}>
      {name}
    </Link>
  );
};

export default BtnWithArrow;
