import Home from '@/components/screens/home/Home';
import { NextPage, GetStaticProps } from 'next';
import { API_URL, REVALIDATE_TIME } from '@/helpers/variables';
import Error from '@/components/screens/error/Error';
import { IBookPage } from '@/interfaces/booksPage.interface';

interface INotFound {
  newBooks: IBookPage;
}

const NotFound: NextPage<INotFound> = ({ newBooks }) => {
  return <Error newBooks={newBooks} />;
};

export const getStaticProps: GetStaticProps = async () => {
  const res = await fetch(`${API_URL}satellite/books/news?page=1&page_size=10`);
  const newBooks = await res.json();

  return { props: { newBooks }, revalidate: REVALIDATE_TIME };
};

export default NotFound;
